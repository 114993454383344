import clsx from "clsx"
import { publicCDN } from "config"
import { plausible } from "plausible"
import { FaFacebook, FaTwitter, FaInstagram } from "react-icons/fa"

export const FacebookSocial = (props) => {
  const { location } = props

  const handleClick = () => {
    plausible("SocialIcon/Click", {
      props: {
        location,
        social: "facebook",
      },
    })
  }

  return (
    <a
      className={clsx(
        "bg-white rounded-full",
        location === "homepage"
          ? "hover:opacity-78 w-10 h-10"
          : "opacity-75 dark:opacity-50 hover:opacity-90 w-8 h-8 land:w-10 land:h-10"
      )}
      target="_blank"
      href="https://www.facebook.com/codenamesgame/"
      onClick={handleClick}
    >
      <div className="w-full h-full flex justify-center items-center">
        <FaFacebook className="text-black text-2xl" />
      </div>
    </a>
  )
}

export const TwitterSocial = (props) => {
  const { location } = props

  const handleClick = () => {
    plausible("SocialIcon/Click", {
      props: {
        location,
        social: "twitter",
      },
    })
  }

  return (
    <a
      className={clsx(
        "bg-white rounded-full",
        location === "homepage"
          ? "hover:opacity-78 w-10 h-10"
          : "opacity-75 dark:opacity-50 hover:opacity-90 w-8 h-8 land:w-10 land:h-10"
      )}
      target="_blank"
      href="https://twitter.com/CodenamesGame"
      onClick={handleClick}
    >
      <div className="w-full h-full flex justify-center items-center">
        <FaTwitter className="text-black text-2xl" />
      </div>
    </a>
  )
}

export const InstagramSocial = (props) => {
  const { location } = props

  const handleClick = () => {
    plausible("SocialIcon/Click", {
      props: {
        location,
        social: "instagram",
      },
    })
  }

  return (
    <a
      className={clsx(
        "bg-white rounded-full",
        location === "homepage"
          ? "hover:opacity-78 w-10 h-10"
          : "opacity-75 dark:opacity-50 hover:opacity-90 w-8 h-8 land:w-10 land:h-10"
      )}
      target="_blank"
      href="https://www.instagram.com/codenamesgame/"
      onAbort={handleClick}
    >
      <div className="w-full h-full flex justify-center items-center">
        <FaInstagram className="text-black text-2xl" />
      </div>
    </a>
  )
}
