import * as React from "react"
import { motion, AnimatePresence } from "framer-motion"
import { Button } from "components/Button"
import { useLocalization } from "features/Localization/useLocalization"

import { useModalManager } from "@vlzh/react-modal-manager"

import { useClickOutside } from "hooks/useClickOutside"
import { useHotkeys } from "react-hotkeys-hook"
import clsx from "clsx"

import { plausible } from "plausible"
import { useGameState } from "hooks/useGameState"

export const VideochatDescriptionIframeModal = (props) => {
  const { current } = useLocalization()
  const { closeModal, isOpen, getParams } = useModalManager(
    "videochat-description-iframe"
  )
  const gameID = useGameState((state) => state.gameID)

  const aroundURL = useGameState((state) => state.G.aroundURL)

  const variant = getParams()?.variant

  const wrapperRef = React.useRef(null)
  useClickOutside(wrapperRef, () => {
    isOpen() ? closeModal() : null
  })
  useHotkeys("esc", () => {
    isOpen() ? closeModal() : null
  })

  React.useEffect(() => {
    if (isOpen()) {
      plausible("AroundPopup/Open", {
        props: { variant: `${variant}/iframe`, language: current },
      })
    }
  }, [isOpen()])

  const handleCloseModal = () => {
    closeModal()
  }

  return (
    <AnimatePresence initial={false}>
      {isOpen() ? (
        <div
          className="fixed inset-0 flex justify-center items-center z-50"
          key="videochat-description-iframe"
        >
          <motion.section
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.9, opacity: 0 }}
            transition={{ duration: 0.2 }}
            className={clsx(
              "flex-none relative rounded-xl shadow-bottom bg-white dark:bg-[#222222] dark:text-white overflow-hidden",
              "w-[95%] max-w-[1362px] h-[95%] max-h-[610px]"
            )}
            ref={wrapperRef}
          >
            <Button
              onClick={handleCloseModal}
              tailwind="absolute top-2 right-2 w-6 h-6 rounded-full z-100 flex justify-center items-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="w-4 h-4"
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </Button>

            <iframe
              className="w-full h-full"
              src={
                variant === "join"
                  ? `https://www.around.co/codenames-game/?aroundURL=${aroundURL}`
                  : `https://www.around.co/codenames-basic/?cno=${gameID}`
              }
            />
          </motion.section>
        </div>
      ) : null}
    </AnimatePresence>
  )
}
