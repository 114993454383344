import * as React from "react"
import { motion, AnimatePresence } from "framer-motion"
import { Button } from "components/Button"
import { useLocalization } from "features/Localization/useLocalization"
import { useGameState } from "hooks/useGameState"
import { useModalManager } from "@vlzh/react-modal-manager"
import isEqual from "react-fast-compare"
import { useClickOutside } from "hooks/useClickOutside"
import { useHotkeys } from "react-hotkeys-hook"
import clsx from "clsx"
import ReactHTMLParser from "react-html-parser"
import { useThemeState } from "features/Theme/useThemeState"
import { plausible } from "plausible"

const aroundCTA = "https://around.co/welcome?flow=7"

export const VideochatDescriptionModal = (props) => {
  const { t, current } = useLocalization()
  const gameID = useGameState((state) => state.gameID)
  const aroundURL = useGameState((state) => state.G.aroundURL)
  const darkMode = useThemeState((state) => state.darkMode)

  const { closeModal, isOpen, getParams } = useModalManager(
    "videochat-description"
  )

  const variant = getParams()?.variant

  const wrapperRef = React.useRef(null)
  useClickOutside(wrapperRef, () => {
    isOpen() ? closeModal() : null
  })
  useHotkeys("esc", () => {
    isOpen() ? closeModal() : null
  })

  React.useEffect(() => {
    if (isOpen()) {
      plausible("AroundPopup/Open", {
        props: { variant: `${variant}/old`, language: current },
      })
    }
  }, [isOpen()])

  const handleCloseModal = () => {
    closeModal()
  }

  const handlePlayButton = () => {
    plausible("AroundPopup/Usage", {
      props: { variant: `${variant}/old`, language: current },
    })

    if (variant === "homepage") {
      window.open(
        `${aroundCTA}&utm_source=codenames&utm_campaign=titlecta`,
        "_blank"
      )
    }

    if (variant === "room") {
      window.open(
        `${aroundCTA}&utm_source=codenames&utm_campaign=menuingamecta&cno=${gameID}`,
        "_blank"
      )
    }

    if (variant === "join") {
      window.open(aroundURL, "_blank")
    }
  }

  return (
    <AnimatePresence initial={false}>
      {isOpen() ? (
        <div
          className="fixed inset-0 flex justify-center items-center z-50"
          key="videochat-description"
        >
          <motion.section
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.9, opacity: 0 }}
            className={clsx(
              "relative rounded-xl shadow-bottom bg-white dark:bg-[#222222] dark:text-white overflow-hidden",
              "w-[95%] max-w-[640px]"
            )}
            ref={wrapperRef}
          >
            <img src="/around/dialog-cover.png" />

            <img
              src={`/around/around-logo${darkMode ? "__dark" : ""}.svg`}
              className={clsx(
                "my-6 w-[80%]",
                variant === "join" ? "mx-auto" : " mx-6"
              )}
            />

            {variant === "join" ? (
              <article className="m-4 land:m-6">
                <h2 className="text-lg font-bold mb-2 text-center">
                  {t("modal.videochatdescription.title.join")}
                </h2>
              </article>
            ) : null}

            {variant === "homepage" || variant === "room" ? (
              <article className="m-4 land:m-6">
                <h2 className="text-lg font-bold mb-2">
                  {t("modal.videochatdescription.title")}
                </h2>

                <section className="videochatDescriptionGlobal">
                  {ReactHTMLParser(t("modal.videochatdescription.description"))}
                </section>
              </article>
            ) : null}

            <hr className={"border-gray-300 dark:border-[#272727]"} />

            <section className="flex items-center justify-center m-4 land:m-6">
              <Button onClick={handlePlayButton} color="green">
                {variant === "homepage"
                  ? t("modal.videochatdescription.button.homepage")
                  : null}
                {variant === "room"
                  ? t("modal.videochatdescription.button.room")
                  : null}
                {variant === "join"
                  ? t("modal.videochatdescription.button.join")
                  : null}
              </Button>
              <div className="w-4" />
              <Button onClick={handleCloseModal}>
                {t("modal.videochatdescription.button.cancel")}
              </Button>
            </section>
          </motion.section>
        </div>
      ) : null}
      <style jsx global>
        {`
          .videochatDescriptionGlobal ul {
            list-style: disc outside none;
            margin-left: 1.5rem;
          }
          .videochatDescriptionGlobal a {
            text-decoration: underline;
          }
          .videochatDescriptionGlobal a:hover {
            text-decoration: none;
          }
        `}
      </style>
    </AnimatePresence>
  )
}
