import * as React from "react"

export const useClickOutside = (
  ref: React.RefObject<HTMLElement> | Array<React.RefObject<HTMLElement>>,
  handler: (event: MouseEvent | TouchEvent) => void
) => {
  const isClickInside = (event) => {
    // you can pass multiple refs and it will check if click was in any of them
    if (Array.isArray(ref)) {
      return ref.some((r) => !r.current || r.current.contains(event.target))
    }

    return !ref.current || ref.current.contains(event.target)
  }

  React.useEffect(
    () => {
      const listener = (event) => {
        // Do nothing if clicking ref's element or descendent elements
        if (isClickInside(event)) {
          return
        }

        handler(event)
      }

      document.addEventListener("mousedown", listener)
      document.addEventListener("touchstart", listener)

      return () => {
        document.removeEventListener("mousedown", listener)
        document.removeEventListener("touchstart", listener)
      }
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler, isClickInside]
  )
}
