import React from "react"
import Router from "next/router"
import Head from "next/head"
import Link from "next/link"
import Image from "next/image"
import clsx from "clsx"
import { AnimatePresence, motion } from "framer-motion"

import { useLocalization } from "features/Localization/useLocalization"
import { webURL, publicCDN } from "config"
import { Flag } from "components/CardAtlases/Flag"
import { NewsletterSubscriptionForm } from "features/NewsletterSubscribe/NewsletterSubscriptionForm"
import { useModalManager } from "@vlzh/react-modal-manager"
import { VideochatDescriptionModal } from "modals/VideochatDescription.modal"
import { VideochatDescriptionIframeModal } from "modals/VideochatDescriptionIframe.modal"
import { ModalOverlay } from "modals/ModalOverlay"
import { plausible } from "plausible"
import {
  FacebookSocial,
  TwitterSocial,
  InstagramSocial,
} from "components/SocialIcons"
import { themeConfig } from "features/Theme/themeConfig"
import { useThemeState } from "features/Theme/useThemeState"

const Home = (props) => {
  const { setLanguage, t, languages, translations, current } = useLocalization()

  const modalManager = useModalManager()

  const theme = useThemeState((state) => state.currentEvent)

  const [showMissingLangPopup, setShowMissingLangPopup] = React.useState(false)
  let missingLangPopupTimeout = null
  React.useEffect(() => {
    if (showMissingLangPopup) {
      missingLangPopupTimeout = setTimeout(() => {
        setShowMissingLangPopup(false)
      }, 5000)
    }
    return () => {
      if (missingLangPopupTimeout) clearTimeout(missingLangPopupTimeout)
    }
  }, [showMissingLangPopup])

  // React.useEffect(() => {
  //     const lang = localStorage.getItem("lang") ?? "en"

  //     if (window.innerWidth >= 1024 && lang === "en") {
  //         setFakeDoor(true)

  //         plausible("HomepageVisit", {
  //             props: { videochatFakedoor: true },
  //         })
  //     } else {
  //         setFakeDoor(false)

  //         plausible("HomepageVisit", {
  //             props: { videochatFakedoor: false },
  //         })
  //     }
  // }, [])

  const handleCreateRoom = async (e) => {
    Router.push(`/room/create`)
  }

  const handleDailyButton = () => {
    Router.push(`/daily?ref=cno-homepage`)
  }

  const showMissingLang = () => {
    setShowMissingLangPopup(true)
  }

  const hidePopups = () => {
    setShowMissingLangPopup(false)
  }

  const handleLanguageChange = React.useCallback(
    (code) => (e) => {
      setLanguage(code)
    },
    []
  )

  return (
    <div className="relative">
      <Head>
        <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/icon_cn/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/icon_cn/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/icon_cn/favicon-16x16.png"
        />
        <link rel="manifest" href="/icon_cn/site.webmanifest" />
        <link rel="shortcut icon" href="/icon_cn/favicon.ico" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta
          name="msapplication-config"
          content="/icon_cn/browserconfig.xml"
        />
        <meta name="theme-color" content="#ffffff" />

        <title>{t("home.meta.title")}</title>
        <meta name="description" content={t("home.meta.description")} />
        <meta property="og:title" content={t("home.meta.title")} />
        <meta property="og:description" content={t("home.meta.description")} />
        <meta property="og:image" content={`${webURL}/img/ogimage.png`} />
        <meta property="og:url" content={`${webURL}`} />
      </Head>

      <main className="flex flex-col w-full xl:min-h-700px overflow-x-hidden relative">
        <div className="absolute w-full h-full -z-10 bg-purple">
          <Image
            className="object-cover"
            alt="Background image"
            src={`${publicCDN}/homepage/bg_colorful.jpg`}
            layout="fill"
            quality={80}
            priority
          />
        </div>

        <div className="relative flex flex-col justify-start items-center z-40">
          <div className="object-contain w-full mt-2 md:mt-6 max-w-2xl">
            <Image
              className="bottom-0"
              alt="Codenames logo"
              layout="responsive"
              width={865}
              height={239}
              src={`${publicCDN}/homepage/logo.png`}
            />
          </div>

          <h1 className="text-vw sm:text-3xl font-black text-white text-shadow mt-2 xl:mt-8">
            {t("home.subtitle")}
          </h1>

          <div className="flex flex-col mb-6 lg:mb-12">
            <button
              className="group transition-transform duration-75 transform active:scale-90 mt-6 mb-2 lg:mt-10 create-button-wrapper cursor-pointer"
              onClick={handleCreateRoom}
              type="button"
            >
              <div className="create-button group-hover:bg-tutorial group-hover:bg-none m-1 lg:px-8">
                {t("home.button.createRoom")}
              </div>
            </button>
            {/* {current === "cs" ? (
              <section className="mx-auto">
                <ButtonCVA
                  color="blue"
                  onClick={handleDailyButton}
                  className="text-lg !px-4 !py-2"
                >
                  Hrát{" "}
                  <span
                    className={clsx(
                      " leading-none uppercase",
                      "drop-shadow-[0_1px_0px_rgb(0,0,0,0.32)]"
                    )}
                  >
                    Krycí jména: Výzvy
                  </span>
                </ButtonCVA>
              </section>
            ) : null} */}
            {current === "cs" ? (
              <a
                className={clsx(
                  `group`,
                  "self-center group transition-transform duration-75 transform active:scale-90 mt-2 cursor-pointer",
                  "bg-gradient-to-br from-[#D7F0FA] to-[#0E5193] rounded-xl shadow-sharp"
                )}
                onClick={handleDailyButton}
              >
                <div
                  className={clsx(
                    "group-hover:bg-[#0287CF] group-hover:bg-none m-1 sm:text-lg py-2 px-4 leading-none",
                    "bg-gradient-to-br from-[#76CBED] via-[#09A2E0] to-[#0287CF] text-white rounded-xl text-center font-bold",
                    "flex justify-between items-center"
                  )}
                >
                  Hrát KRYCÍ JMÉNA: VÝZVY
                </div>
              </a>
            ) : null}

            {/* <a
              className={clsx(
                `group`,
                "self-center group transition-transform duration-75 transform active:scale-90 mt-2 cursor-pointer",
                "bg-gradient-to-br from-[#e7a2cd] to-[#99015b] rounded-xl shadow-sharp"
              )}
              onClick={handleDailyButton}
            >
              <div
                className={clsx(
                  "group-hover:bg-[#99015b] group-hover:bg-none m-1 text-sm py-0.5 px-4 leading-none",
                  "bg-gradient-to-br from-[#cc3b94] via-[#b50470] to-[#99015b] text-white rounded-xl text-center font-bold",
                  "flex justify-between items-center"
                )}
              >
                <img
                  src={`${publicCDN}/img/icon/icon_videochat.png`}
                  className="w-6 mr-2"
                />
                {t("home.button.createVideochat")}
              </div>
            </a> */}
          </div>
        </div>

        <div className="grid relative auto-cols-min grid-cols-2 md:grid-cols-3 justify-center align-center w-full mt-auto">
          <div className="relative md:col-start-1">
            <div className="absolute flex justify-end bottom-0 w-38vw md:w-30vw 1201px:w-auto -left-6 sm:-left-10 1201px:-left-50 1460px:left-10 rtl:left-20">
              <img
                className=""
                src={themeConfig[theme].homepage.rightAgentImage}
                alt="Left agent"
              />
            </div>
          </div>
          <div className="tutorial relative text-xl leading-7 1201px:leading-8 justify-self-center col-span-2 md:col-span-1">
            <h2 className="font-bold text-2xl text-purple mb-2">
              {t("home.info.howtoplay.title")}
            </h2>
            <b>1.</b> {t("home.info.howtoplay1")}
            <br />
            <b>2.</b> {t("home.info.howtoplay2")}
            <br />
            <b>3.</b> {t("home.info.howtoplay.chat")}
            <br />
            <b>4.</b> {t("home.info.howtoplay3")}
            <br />
            <b>5.</b> {t("home.info.howtoplay4")}
            <br />
            <div className="absolute right-10 bottom-10">
              <a href="https://czechgames.com/">
                {t("home.info.howtoplay.cge")}
              </a>
            </div>
          </div>
          <div className="relative col-start-2 row-start-1 md:col-start-3">
            <div className="absolute flex justify-end bottom-0 w-32vw md:w-26vw 1201px:w-auto -right-6 sm:-right-10 1201px:-right-50 1460px:right-10 rtl:right-20">
              <img
                className=""
                src={themeConfig[theme].homepage.leftAgentImage}
                alt="Right agent"
                width={412}
                height={432}
              />
            </div>
          </div>
        </div>
      </main>
      <section className="bg-purple w-full pt-2 pb-4 px-4">
        <div className="flex flex-wrap justify-center content-between mx-auto max-w-3xl">
          {languages.map((lang) => {
            if (translations[lang]) {
              return (
                <Flag
                  lang={lang}
                  width={30}
                  className="cursor-pointer m-2px"
                  key={`flag-${lang}`}
                  onClick={handleLanguageChange(lang)}
                />
              )
            } else {
              return (
                <Flag
                  lang={lang}
                  width={30}
                  className="cursor-pointer m-2px"
                  key={`flag-${lang}`}
                  title="Website localization not yet available"
                  onClick={showMissingLang}
                />
              )
            }
          })}
        </div>
      </section>
      <section className="bg-darkPurple flex flex-col sm:flex-row justify-center content-between text-white">
        <NewsletterSubscriptionForm tag="homepage" />
      </section>

      <section className="bg-blackPurple text-center py-6">
        <div className="mb-4">
          <Link
            href="/faq"
            className="underline hover:no-underline text-white text-lg cursor-pointer"
          >
            {t("home.button.FAQ")}
          </Link>
        </div>
        <p className="text-sm leading-4 text-lightGray">
          {t("home.info.footer")}
          <br />
          {t("home.info.footer.support")}
          <br />
          {t("home.info.footer.thankYou")}
        </p>
      </section>
      <footer className="bg-black py-10">
        <div className="flex flex-col mx-auto text-white justify-center xl:flex-row xl:justify-around">
          <a
            className="flex flex-col xl:flex-row justify-between items-center"
            target="_blank"
            href="http://czechgames.com/"
          >
            <div className="xl:mr-24 flex justify-center">
              <Image
                src={`${publicCDN}/homepage/footer-logo.png`}
                alt="Czech Games Edition Logo"
                width={115}
                height={59}
              />
            </div>
            <p className="mt-6 xl:mt-0">
              Copyright © {new Date().getFullYear()} Czech Games Edition
            </p>
          </a>
          <div className="flex justify-center xl:justify-between items-center text-darkGray mt-6 xl:mt-0">
            <a
              className="transition duration-500 hover:text-white"
              target="_blank"
              href="http://czechgames.com/"
            >
              CzechGames.com
            </a>
            <span className="text-2xl font-bold text-gray-800 mx-6">
              &#183;
            </span>
            <a
              className="transition duration-500 hover:text-white"
              target="_blank"
              href="http://codenamesgame.com/"
            >
              CodenamesGame.com
            </a>
          </div>

          <div className="flex flex-wrap items-center justify-center mx-6 mt-6 xl:mt-0 gap-6">
            <FacebookSocial location="homepage" />
            <TwitterSocial location="homepage" />
            <InstagramSocial location="homepage" />
          </div>
        </div>
      </footer>

      <ModalOverlay />

      <VideochatDescriptionModal />
      <VideochatDescriptionIframeModal />

      <AnimatePresence initial={false}>
        {showMissingLangPopup ? (
          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{
              opacity: 1,
              scale: 1,
              transition: { duration: 0.2 },
            }}
            exit={{
              opacity: 0,
              scale: 0,
              transition: { duration: 0.2 },
            }}
            className="transition duration-150 fixed z-50 my-5vh left-0 right-0 bottom-0 mb-16 mx-auto max-w-sm"
            onClick={hidePopups}
          >
            <section className="text-center bg-white shadow-cno rounded-xl py-4 px-1 mx-3 cursor-pointer">
              <p>
                You can play with word cards in this language but website
                localization is not yet available.
              </p>
            </section>
          </motion.div>
        ) : null}
      </AnimatePresence>
    </div>
  )
}

export default Home
