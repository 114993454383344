import * as React from "react"
import { motion, AnimatePresence } from "framer-motion"
import { manager } from "@vlzh/react-modal-manager"
import { useSceneStore } from "hooks/useGameSceneSize"

export const ModalOverlay = (props) => {
    const [isOpen, setOpen] = React.useState(false)

    const handleOpen = () => {
        // console.log("manager", manager.open)
        const showOverlay =
            manager.open.length > 0 &&
            !manager.open.some((modal) => modal.params.disableOverlay)
        if (showOverlay) {
            setOpen(true)
        } else {
            setOpen(false)
        }
    }

    React.useEffect(() => {
        manager.on("afterClose", handleOpen)
        manager.on("afterOpen", handleOpen)
    }, [manager, setOpen])

    const handleClick = () => {
        if (manager.open.length > 0) return
        setOpen(false)
    }

    return (
        <AnimatePresence>
            {isOpen ? (
                <motion.div
                    initial={false}
                    animate={{ opacity: 0.5 }}
                    exit={{ opacity: 0, transition: { duration: 0.2 } }}
                    className="absolute inset-0 bg-black z-40"
                    onClick={handleClick}
                ></motion.div>
            ) : null}
        </AnimatePresence>
    )
}
